<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase()+'-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br>
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany"/>
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button type="button" @click="signature_ids=[]" v-if="signature_ids.length && original_signature_ids.length">
                    Edit & Create Addendum
                </button>
                <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right" type="button" @click="signature_ids=original_signature_ids">
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <div class="max800">
                    <p>This ‘Intern Informed Consent’ document is intended to provide information about the counseling services and business practices provided by {{ company.company_name }} when being seen by one of their student interns. Please review the information below and ask your clinician if you have any questions. Your signature indicates an acknowledgement and agreement between you, your clinician, and {{ company.company_name }}.</p>
                    <p>By signing below, I am acknowledging that I understand:</p>
                    <ul>
                        <li>My child, my family, or I will receive therapy services from a student intern who is under the supervision of {{ company.company_name }} and a field placement office of their education’s institution. All interns are supervised at {{ company.company_name }} by licensed clinicians as well as the acting supervisor for their educational institution.</li>
                        <li>Student interns are bound by the ethical guidelines of their profession and adhere to the guidelines specified by {{ company.company_name }} service agreement, Telehealth Consent, Internship Supervision Agreement of their education’s institution and Notice of Privacy Practices/HIPAA.</li>
                        <li>Student interns have completed most masters level education from their educational institution in their field of study, have demonstrated core competencies and have been determined by their educational institution as ready to apply their clinical skills to working with clients.</li>
                        <li>Student interns receive intensive ongoing guidance, evaluation and education in providing excellence in clinical skills to you and your family members. By working with a student intern, each client receives the benefit of a clinically experienced supervision team assisting in assessment and treatment planning to address concerns in therapy.</li>
                        <li>Student interns may provide counseling sessions in conjunction with a fully licensed clinician, and when deemed ready by {{ company.company_name }} will provide counseling sessions without a supervising clinician present.</li>
                        <li>Sessions conducted by student interns may include recordings of sessions, for use in supervision. Recordings may not be used for any other purpose than for use in supervision and are stored on a password protected device and are destroyed at the termination of therapy.</li>
                        <li>Clients may terminate this agreement at any time, but termination of this agreement will require transfer to another clinician as interns cannot be adequately supervised in cases that do not consent to recording.</li>
                    </ul>
                    <h3>I, the client or legal guardian of a minor, acknowledge that I am voluntarily authorizing treatment for myself or my child at {{ company.company_name }} by a student intern. I have been informed of the purpose of the treatment, the services which may be provided, and any attendant risks, consequences, and/or benefits.</h3>
                </div>

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button v-if="original_signature_ids.length && !signature_ids.length" class="align-right inline-block secondary right-15" type="button" @click="signature_ids=original_signature_ids">
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />

                </div>
                <FormSignature :user_id="user_id" :signature_ids="signature_ids" ref="form_signatures" @getSignatures="getSignatures" v-if="signature_ids.length > 0"/>
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal
                :hasCloseButton="false"
                data-html2canvas-ignore="true"
                v-if="showCreatePdfModal"
            >
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew'
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex'
    import { merge } from 'lodash'

    export default {
        name: 'InternInformedConsent',
        props: {
            record_id: {
                type: [String, Number],
                required : false,
                default : 0
            },
        },
        components: { FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
            }
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false)
            },
            async reloadData(signature){
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature)
            },
            showPdfModal(bool){
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data){
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating  = 1
                    try{e.preventDefault()}catch(error){/**/}

                    let xthis = await dryalex.form_data_update_record(this, e)
                    merge(this, xthis)
                    if (this.router_push) {
                        this.router_push = 1
                        this.$forceUpdate()
                    }
                    this.original_signature_ids = this.signature_ids
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this)
                merge(this, xthis)
                this.loading = 0
            },
        },
        async created() {
            await this.init()
        },
        watch: {
            showCreatePdfModal(newVal){
                if(window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if(newVal == false){this.closeModalOverlay()}
            },
        }
    }
</script>